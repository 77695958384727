var supports = require('./feature-support.js');

var videoElement, vid, fallbackMode = false;

function createFallback(element, path) {
  if (!element || !path) return;
  element.style.backgroundImage = 'url(' + path + ')';
}

function onVideoEnd(e) {
  vid.currentTime = 0;
  vid.play();
}

// return true if video is created successfully
function createVideo(element, videos) {
  if (!element || !videos) return false;
  vid = document.createElement('video');
  if (!supports.video(vid)) return false;

  for (var i = 0; i < videos.length; i++) {
    var v = videos[i];
    var e = document.createElement('source');
    e.src = v.src;
    e.type = v.type;
    vid.appendChild(e);
  }

  vid.volume = 0;
  vid.className = 'bg-video';
  element.appendChild(vid);
  vid.play();
  vid.addEventListener('ended', onVideoEnd);

  return true;
}

function initialize(elementName, videos, fallbackImage) {
   fallbackMode = true;

  if (elementName) {
    videoElement = document.getElementById(elementName);
  }

  if (videos && videoElement) {
    if (videos.length) {
      fallbackMode = !createVideo(videoElement, videos);
    }
  }

  if (fallbackMode && fallbackImage && videoElement) {
    createFallback(videoElement, fallbackImage);
  }
  
}

module.exports = {
  init: initialize
};
