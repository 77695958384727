var padding = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

var smallPadding = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

function initializeSite(cb) {
  cb();
}

function onWindowResize() {
  //adjustPornzone();
}

function listenWindowResize() {
  window.addEventListener('resize', onWindowResize);
}

var Site = {
  smallBreakpoint: 768,
  padding: padding,
  smallPadding: smallPadding,
  init: initializeSite,
};

module.exports = Site;