var classie = require('classie');
var util = require('./util.js');
var supports = require('./feature-support');
var supportsTransition = supports.cssFeature('transition');

var feed = {};
feed.maxItems = 8;

var feedContainer = document.querySelector('.showcase') || document;
var feedItems = feedContainer.querySelectorAll('.feeditem');
var thumbnails = feedContainer.querySelectorAll('.thumbnail');

function addColumnClasses(index,item) {
  if (index === 0) {
    classie.add(item,'col-md-12');
    classie.add(item.querySelector('.thumbnail'),'emphasis');
  } 
  else if (index === 1) {
    classie.add(item,'col-md-7');
    classie.add(item.querySelector('.thumbnail'),'emphasis');
  }
  else if (index === 2 || index === 3) {
    classie.add(item,'col-md-5');
    classie.add(item.querySelector('.thumbnail'),'half-height');
    classie.add(item.querySelector('.thumbnail'),'emphasis');
  }
  else if (index > 3 && index < feed.maxItems - 1){
    classie.add(item,'col-md-4');
    classie.add(item.querySelector('.thumbnail'),'half-height');
  } else {
    classie.add(item,'col-md-12');
    classie.add(item.querySelector('.thumbnail'),'auto-height');
  }
}

function getRealOffset(elem) {
  var element = elem;
  var offset = 0;

  while (element.parentNode && element.parentNode.nodeType !== 9) {
    offset += element.offsetTop;
    element = element.parentNode;
  }

  return offset;
}

function checkThumbnailPosition(index,item) {
  var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  if (scrollTop + window.innerHeight >= getRealOffset(item) - (item.clientHeight)) {
    classie.add(item,'in');
  } else {
    classie.remove(item,'in');
  }
}

function checkPositions(e) {

  if (!supportsTransition) return;
  if (!thumbnails) return;

  util.each(thumbnails,checkThumbnailPosition);

}

function setColumns() {

  if (!feedItems) return;
  util.each(feedItems,addColumnClasses);

}

//checkPositions();
setColumns();

// just open all if transition is not supported
if (thumbnails && !supportsTransition) {
  util.each(thumbnails, function(i,thumb) {
    classie.add(thumb,'in');
  });
}

feed.checkPositions = checkPositions;
feed.setColumns = setColumns;

module.exports = feed;