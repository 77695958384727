var util = require('./util');
var classie = require('classie');
var evt = require('eventlistener');

var hovers = {};

hovers.nodelist = [];

hovers.query = function(selector) {
  hovers.nodelist = document.querySelectorAll(selector);
  return hovers;
};

function onMouseEnter(e) {
  classie.add(e.target,'active');
}

function onMouseLeave(e) {
  classie.remove(e.target,'active');
}

function bindEvents(i,elem) {
  evt.add(elem,'mouseenter',onMouseEnter);
  evt.add(elem,'mouseleave',onMouseLeave);
}

hovers.bind = function() {
  util.each(hovers.nodelist,bindEvents);
};

hovers.init = function(selector) {
  hovers.query(selector).bind();
};

module.exports = hovers;