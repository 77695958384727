var classie = require('classie');
var transitionend = require('./transitionend.js');
var evt = require('eventlistener');

var navigation = {};
var headerElem = document.getElementById('main-header');
var navigationElem = document.getElementById('navigation');
var oldPos = 0;

function checkPosition(e){
  var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

  //do not tolerate equality
  if (oldPos < scrollTop) {
    classie.remove(headerElem,'in');
  } else if (oldPos > scrollTop) {
    classie.add(headerElem,'in');
  }

  if (scrollTop > headerElem.clientHeight) {
    classie.add(headerElem,'alt');
  } else {
    classie.remove(headerElem,'alt');
  }

  oldPos = scrollTop;
}


function showNavigation() {
  document.body.style.overflow = 'hidden';
  classie.remove(navigationElem,'leave');
  classie.remove(navigationElem,'leave-active');
  classie.add(navigationElem,'enter');
  setTimeout(function(){
    classie.add(navigationElem,'enter-active');    
  },50);
}

function hideNavigation() {
  document.body.style.overflow = 'visible';
  classie.remove(navigationElem,'enter');
  classie.remove(navigationElem,'enter-active');
  classie.add(navigationElem,'leave');
  setTimeout(function(){
    classie.add(navigationElem,'leave-active');
    transitionend(navigationElem).bind(function(e){
      classie.remove(navigationElem,'leave');
      classie.remove(navigationElem,'leave-active');
      transitionend(navigationElem).unbind();
    });
  },50);
}

function toggleNavigation() {
  navigation.out = !navigation.out;
  if (navigation.out) {
    showNavigation();
  } else {
    hideNavigation();
  }
}

function navigationToggleTap(e) {
  e.preventDefault();
  navigationToggleClick(e);
}

function navigationToggleClick(e) {
  toggleNavigation();
}

var toggleElem = document.getElementById('navigation-toggle');
if (toggleElem) {
  evt.add(toggleElem, 'touchend', navigationToggleTap);
  evt.add(toggleElem, 'click', navigationToggleClick);
}

navigation.checkPosition = checkPosition;

module.exports = navigation;