var util = {};

util.each = function(arr,cb) {

  var i = 0, 
      l = arr.length;

  if (l > 0) {
    for(; i < l; i++) {
      cb.call(arr[i],i,arr[i]);
    }
  }

};

util.extend = function() {

  var i = 1,
      l = arguments.length;
      target = arguments[0] || {};

  if (l > 1) {
    for (; i < l; i++) {
      var source = arguments[i];
      for (var key in source) {
        target[key] = source[key];
      }
    }
  }

  return target;

};

module.exports = util;