var debounce = require('debounce');
var site = require('./modules/site');
var evt = require('eventlistener');
var util = require('./modules/util');
var feed = require('./modules/feed');
var navigation = require('./modules/navigation');
var bgVideo = require('./modules/bg-video');
var hovers = require('./modules/hovers');
var clients = require('./modules/_clients');
// var reel = require('./modules/reel');
var logo = require('./modules/logo');
var heightMatcher = require('./modules/height-matcher');

//css feature support
var supports = require('./modules/feature-support');
var supportsTransition = supports.cssFeature('transition');
var supportsAnimation = supports.cssFeature('animation');
var supportsTransform = supports.cssFeature('transform');
var supportsPerspective = supports.cssFeature('perspective');

var isTrident = /Trident/.test(navigator.userAgent);

//3d logo
if (supportsAnimation && supportsTransform && supportsPerspective && !isTrident) {
  // logo.build();
}

//get tweets
/*
var tweetContainer = document.getElementById('tweets');
var twitter = require('./modules/twitter');
twitter.get(3,function(res){
  twitter.render(res.body,tweetContainer);
});
*/

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|MSIE 9|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
   bgVideo.init('about', null, 'https://s3-eu-west-1.amazonaws.com/movya-website/loop_videos/unito-loop-fallback.png');
} else {
  bgVideo.init(
    'about', 
    [
      {
        src: 'https://movya-website.s3.eu-west-1.amazonaws.com/loop_videos/Movya_Storyverse_MWC2023.webm',
        type: 'video/webm'
      },
      {
        src: 'https://movya-website.s3.eu-west-1.amazonaws.com/loop_videos/Movya_Storyverse_MWC2023.mp4',
        type: 'video/mp4'
      }      
    ], 
    'https://s3-eu-west-1.amazonaws.com/movya-website/loop_videos/unito-loop-fallback.png');
}

//adjust heights
heightMatcher.adjust();
setTimeout(heightMatcher.adjust, 200);

//initialize hover behaviour
hovers.init('.hoverable');

//add clients to the footer
clients.init();

//calculate video position and size
// reel.matchVideo();

function onWindowResize(e) {
  heightMatcher.adjust();
  // reel.matchVideo();
}

function onWindowScroll(e) {
  //navigation.checkPosition(e);
  if (supportsTransition) {
    //feed.checkPositions(e);
  }
}

evt.add(window, 'resize', debounce(onWindowResize,16));
evt.add(window, 'scroll', debounce(onWindowScroll,16));
