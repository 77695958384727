var classie = require('classie');

var logo = {};

logo.el = document.querySelector('#logo');

logo.build = function() {

  var sides = 6;

  var cube = document.createElement('div');
  classie.add(cube,'cube');

  for (var i = 0; i < sides; i++) {

    var side = document.createElement('div');
    classie.add(side,'side');
    classie.add(side,'side'+(i+1));

    if (i === 0) {
      side.innerHTML = logo.el.innerHTML;
      logo.el.innerHTML = "";
    }

    cube.appendChild(side);

  }

  logo.el.appendChild(cube);

  classie.add(logo.el,'logo3d');
  classie.add(cube,'spin');

};

module.exports = logo;