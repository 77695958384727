var fs = require('fs');
var util = require('./util');
var classie = require('classie');

var clients = {};

clients.images = {
  filenames: 'abb.png,aidon.png,airbus-ds.png,arctic-machine.png,daikin.png,ensto.png,huurre.png,jyu.png,kemppi.png,kone.png,lojer.png,metsaboard.png,metso.png,outotec.png,paroc.png,ruukki.png,thermo.png,valmet-automotive.png,valmet.png,valtra.png'.split(','),
  elements: []
};

util.each(clients.images.filenames, function(i,name){

  var el = document.createElement('img');
  el.src = '/images/clients/' + name;
  classie.add(el,'img-responsive');
  clients.images.elements.push(el);

});

clients.container = document.querySelector('#clients .row');

clients.init = function() {

  util.each(clients.images.elements, function(i,el){

    var div = document.createElement('div');
    classie.add(div,'client');
    classie.add(div,'col-lg-3');
    classie.add(div,'col-md-4');
    classie.add(div,'col-sm-3');
    classie.add(div,'col-xs-6');

    div.appendChild(el);

    if (clients.container) {
      clients.container.appendChild(div);
    }
    

  });

};

module.exports = clients;