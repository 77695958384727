var util = require('./util');
var site = require('./site');

var heightMatcher = {};

var elements = document.querySelectorAll('.match-height');

function adjustHeight(i,elem) {
  var re,ref,ignore,min = 0,max,padding,condition,multiplier = 1;
  
  //test for min height

  if ((re = /match-min-(\w+)/g.exec(elem.className)) !== null) {

    switch (re[1]) {
      case 'content':
        var content = elem.querySelector('.content');
        min = content.offsetTop * 2 + content.clientHeight;
        break;
      default: 
        min = re[1] || 0; 
    }

  }

  //test for max height
  if ((re = /match-max-(\w+)/g.exec(elem.className)) !== null) {

    switch (re[1]) {
      case 'window':
        max = window.innerHeight;
        break;
      default: 
        max = parseInt(re[1]) || 800; 
    }

  }

  //test if the current viewport width is ignored
  if ((re = /match-ignore-(\w+)/g.exec(elem.className)) !== null) {

    //test for viewport size string aliases
    switch (re[1]) {
      case 'xs':
        ignore = 479;
        break;
      case 'sm':
        ignore = 991;
        break;
      case 'md':
        ignore = 1199;
        break;

      default:
        ignore = null;
    }
  }

  //test if the current viewport width is ignored
  if ((re = /match-height-if-(\w+)/g.exec(elem.className)) !== null) {

    //test for viewport size string aliases
    switch (re[1]) {
      case 'smaller':
        condition = function(ref,el) {
          return el.clientHeight < ref.clientHeight;
        };
        break;
      case 'bigger':
        condition = function(ref,el) {
          return el.clientHeight > ref.clientHeight;
        };
        break;

      default:
        condition = null;
    }

  }

  if (ignore && window.innerWidth <= ignore) {
    elem.style.height = 'auto';
    return;
  }

  //test for "with-something"
  if ((re = /match-height-(\w+)-(\w+)/g.exec(elem.className)) !== null) {
    //test for relative elements
    switch (re[2]) {
      case 'previous':
        ref = elem.previousSibling || null;
        break;
      case 'next':
        ref = elem.nextSibling || null;
        break;
      case 'window':
        ref = window || null;
        break;

      default:
        ref = null;
    }

    if (re[1] === 'halfof') {
      multiplier = 0.5;
    }

    //otherwise ref must be an id, or you're using it wrong
    if (!ref) {
      ref = document.querySelector('#'+re[2]);
    }

    if (ref) {

      if (condition && !condition(ref,elem)) {
        return;
      }

      if (ref.innerHeight <= min) {
        elem.style.height = min + 'px';
        return;
      }

      if (max && ref.clientHeight >= max) {
        elem.style.height = max + 'px';
        return;
      }

      if (ref === window) {
        if (ref.innerWidth > site.smallBreakpoint) {
          padding = site.padding.top + site.padding.bottom;
        }
        else {
          padding = site.smallPadding.top + site.smallPadding.bottom;
        }
        elem.style.height = (ref.innerHeight > 480) ? (ref.innerHeight - padding) * multiplier + 'px' : '480px';
      } 
      else {
        elem.style.height = ref.clientHeight * multiplier + 'px';
      }
      
    }
  }
}

heightMatcher.adjust = function(){
  util.each(elements,adjustHeight);
};

heightMatcher.add = function(elem){
  elements.push(elem);
};

module.exports = heightMatcher;